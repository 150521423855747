<template>
    <el-main>
        <el-button size="small" type="primary" style="margin-bottom:16px"
            @click="$router.push('/extension/cooperation/add')">+ 添加合作</el-button>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="合作类型：">
                <el-select size="small" v-model="ruleForm.project_type" filterable remote :remote-method="getCateList"
                    placeholder="请选择合作类型" class="classify_select">
                    <el-option v-for="item in cateInfo.list" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合作标题：">
                <el-input size="small" v-model="ruleForm.title" placeholder="请输入合作标题"></el-input>
            </el-form-item>
            <el-form-item label="上架时间：">
                <el-date-picker size="small" v-model="ruleForm.upTime" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="需求类型：">
                <el-select size="small" v-model="ruleForm.need_type" multiple placeholder="请选择">
                    <el-option v-for="item in demandList" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上线状态：">
                <el-select size="small" v-model="ruleForm.is_up" placeholder="请选择">
                    <el-option v-for="item in upStatus" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="数据来源：">
                <el-select size="small" v-model="ruleForm.comfrom" placeholder="请选择">
                    <el-option v-for="item in comfromList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属城市：">
                <el-cascader size="small" v-model="ruleForm.city_id" :options="allAreaList"
                    :props="{ expandTrigger: 'hover', children: '_child', label: 'name', value: 'id' }">
                </el-cascader>
            </el-form-item>
            <el-form-item label="会员姓名：">
                <el-input size="small" v-model="ruleForm.nick_name" placeholder="请输入会员姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系方式：">
                <el-input size="small" v-model="ruleForm.mobile" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
                <el-button size="small" type="text" @click="clearSearch">清除搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column align="center">
                <template #header>
                    <div class="rank">
                        <span>排序</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template v-slot="{ row }">
                    {{ row.sort }}
                </template>
            </el-table-column>
            <el-table-column prop="project_name" label="活动分类" align="center"></el-table-column>
            <el-table-column prop="title" label="合作标题" align="center"></el-table-column>
            <el-table-column label="列表图" align="center" width="100px">
                <template v-slot="{ row }">
                    <el-image style="width: 70px; height:70px" :src="row.image" :preview-src-list="[row.image]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column label="数据来源" align="center">
                <template v-slot="{ row }">
                    {{ row.comfrom === 1 ? '后台上传' : '小程序上传' }}
                </template>
            </el-table-column>
            <el-table-column label="上传会员昵称" align="center">
                <template v-slot="{ row }">
                    {{ row.nick_name || '--' }}
                </template>
            </el-table-column>
            <el-table-column label="会员手机号" align="center">
                <template v-slot="{ row }">
                    {{ row.mobile || '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="need_type" label="需求类型" align="center"></el-table-column>
            <el-table-column label="预计孵化周期" align="center">
                <template v-slot="{ row }">
                    {{ row.time + '天' }}
                </template>
            </el-table-column>
            <el-table-column prop="start_price" label="项目启动资金" align="center"></el-table-column>
            <el-table-column prop="price" label="市场预期收益(元)" align="center"></el-table-column>
            <el-table-column label="项目实施计划" align="center" width="100px">
                <template v-slot="{ row }">
                    <el-popover placement="top" width="200" trigger="hover" :content="row.plan">
                        <div slot="reference">查看计划<i class="el-icon-info" style="color:#409EFF"></i></div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="所属区域" align="center" width="200px">
                <template v-slot="{ row }">
                    {{ row.province_name + row.city_name }}
                </template>
            </el-table-column>
            <el-table-column label="进行状态" align="center">
                <template v-slot="{ row }">
                    {{ getTimeStatus(row) }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="上线时间" align="center" width="200px">
                <template v-slot="{ row }">
                    {{ row.is_up ? getDateformat(row.up_time) : '--' }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="到期时间" align="center" width="200px">
                <template v-slot="{ row }">
                    {{ getDateformat(row.end_time) }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="展示/下架" align="center">
                <template v-slot="{ row }">
                    <el-switch v-model="row.is_up" :active-value="1" :inactive-value="0"
                        @change="upChange(row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="200px">
                <template v-slot="{ row }">
                    <el-button type="text"
                        @click="$router.push('/extension/cooperation/edit?edit_id=' + row.id)">编辑</el-button>
                    <el-button type="text" @click="deleteData(row)">删除</el-button>
                    <el-button type="text" @click="toOrder(row)">合作申请</el-button>
                    <el-button v-if="!row.is_up" type="text" @click="lookRejecjReault(row)">下架原因</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="dataTotal" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
        <!-- 下架弹框 -->
        <el-dialog title="驳回" :visible.sync="rejectDialogFlag" width="400px" @closed="dealReject(0)">
            <div>
                <p style="margin-bottom:20px">请添加下架原因：</p>
                <el-input v-model="rejectResultTxt" type="textarea" :rows="5"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dealReject(0)">取 消</el-button>
                <el-button type="primary" @click="dealReject(1)">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="rejectResultFlag" width="400px">
            <div>{{ nowEditData.down_reason }}</div>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate.js'
export default {
    components: {
        Paging
    },
    data () {
        return {
            getDateformat,
            ruleForm: {
                page: 1,
                rows: 10,
                project_type: null,
                title: '',
                upTime: [],
                need_type: null,
                is_up: -1,
                comfrom: 0,
                city_id: null,
                nick_name: '',
                mobile: ''
            },
            cateInfo: {
                name: '',
                list: []
            },
            dataList: [],
            dataTotal: 0,
            allAreaList: [],
            demandList: ['项目融资', '平台推广', '线下活动', '人力资源', '项目外包', '技术合作'],
            upStatus: [
                { id: -1, name: "全部" },
                { id: 1, name: "上线" },
                { id: 0, name: "未上线" },
            ],
            comfromList: [
                { id: 0, name: '全部' },
                { id: 1, name: '平台上传' },
                { id: 2, name: '会员上传' },
            ],
            // 驳回 || 显示驳回原因
            nowEditData: {},
            rejectDialogFlag: false,
            rejectResultFlag: false,
            rejectResultTxt: '',
        }
    },
    created () {
        this.getCateList()
        this.getArea()
        this.getDataList()
    },
    methods: {
        // 跳转到合作订单
        toOrder (data) {
            this.$router.push('/extension/cooperation/examine?cid=' + data.id)
        },
        // 上下架活动
        upChange (data) {
            if (data.is_up) {
                this.$confirm('此操作将上架该合作, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    return this.$axios.post(this.$api.gateway.cooperation.up, {
                        id: data.id,
                        is_up: data.is_up
                    })
                }).then(res => {
                    if (res.code === 0) {
                        this.$message.success('上架成功')
                        data.up_time = Math.floor(new Date().getTime() / 1000)
                    } else {
                        this.$message.error(res.msg)
                        data.is_up = 0
                    }
                }).catch(() => {
                    data.is_up = 0
                })
            } else {
                // 下架
                this.nowEditData = data
                data.is_up = 1
                this.rejectDialogFlag = true
            }
        },
        // 处理驳回
        dealReject (style) {
            if (!style) {
                // 取消驳回
                this.rejectDialogFlag = false
                this.rejectResultTxt = ''
            } else {
                // 确定驳回
                if (this.rejectResultTxt === '') {
                    this.$message.warning('请填写下架理由!!!')
                    return
                }
                this.$axios.post(this.$api.gateway.cooperation.up, {
                    id: this.nowEditData.id,
                    is_up: 0,
                    down_reason: this.rejectResultTxt
                }).then(res => {
                    if (res.code === 0) {
                        this.$message.success('下架成功')
                        this.dealReject(0)
                        this.getDataList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        lookRejecjReault (data) {
            this.nowEditData = data
            this.rejectResultFlag = true
        },
        // 删除数据
        deleteData (data) {
            this.$confirm('此操作将永久删除该合作, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                return this.$axios.post(this.$api.gateway.cooperation.del, {
                    id: data.id
                })
            }).then(res => {
                if (res.code == 0) {
                    this.$message.success('删除成功')
                    this.getDataList()
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(() => { });
        },
        // 判断活动此时的状态
        getTimeStatus (data) {
            let nowTime = Math.ceil(new Date().getTime() / 1000)
            if (nowTime < data.start_time) return '未开始'
            if (nowTime >= data.start_time || nowTime <= data.end_time) return '进行中'
            if (nowTime > data.end_time) return '已完成'
        },
        // 获取省市区信息
        getArea () {
            this.$axios
                .get(this.$api.shopDiy.areaList, {
                    tree: 1,
                    level: 1,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.allAreaList = res.result.list;
                    }
                });
        },
        getCateList (name) {
            this.cateInfo.name = name;
            this.$axios
                .post(this.$api.gateway.service.threeLevelList, {
                    name,
                    type: 7,
                })
                .then(res => {
                    if (res.code === 0) {
                        this.cateInfo.list = res.result || [];
                    }
                });
        },
        updateData (val, status) {
            if (status == 0) {
                this.ruleForm.rows = val;
            } else {
                this.ruleForm.page = val;
            }
            this.getDataList()
        },
        clearSearch () {
            this.ruleForm = {
                page: 1,
                rows: 10,
                project_type: null,
                title: '',
                upTime: [],
                need_type: null,
                is_up: -1,
                comfrom: 0,
                nick_name: '',
                mobile: ''
            }
            this.getDataList()
        },
        getDataList (style) {
            if (style) this.ruleForm.page = 1
            let obj = {
                page: this.ruleForm.page,
                rows: this.ruleForm.rows,
            }
            if (this.ruleForm.project_type) obj.project_type = this.ruleForm.project_type
            if (this.ruleForm.title) obj.title = this.ruleForm.title
            if (this.ruleForm.nick_name) obj.nick_name = this.ruleForm.nick_name
            if (this.ruleForm.mobile) obj.mobile = this.ruleForm.mobile
            if (this.ruleForm.need_type?.length) obj.need_type = this.ruleForm.need_type.join(',')
            if (this.ruleForm.is_up !== -1) obj.is_up = this.ruleForm.is_up
            if (this.ruleForm.comfrom) obj.comfrom = this.ruleForm.comfrom
            if (this.ruleForm.upTime?.length) {
                obj.start_time = Math.ceil(this.ruleForm.upTime[0].getTime() / 1000)
                obj.end_time = Math.ceil(this.ruleForm.upTime[1].getTime() / 1000)
                obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time
            }
            if (this.ruleForm.city_id?.length) {
                obj.city_id = this.ruleForm.city_id[1]
            }
            this.$axios.post(this.$api.gateway.cooperation.getList, obj).then(res => {
                if (res.code == 0) {
                    this.dataList = res.result.list
                    this.dataTotal = res.result.total
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
}
</style>